@import "./components/variables";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

.grid {
  position: relative;
  background-color: #fafff9;
  height: 1000px;
  &__background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &__container {
    height: 100%;
    position: relative;
  }
  &__row {
    position: relative;
    z-index: 2;
    height: 100%;
    &::before, &:after {
      content: '';
      display: block;
      width: $container-padding-x;
      background-color: #dfffd6;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 4;
    }
    &::before {
      left: -$grid-gutter-width/2;
    }
    &::after {
      right: -$grid-gutter-width/2;
    }
  }
  &__col {
    position: relative;
    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        width: $grid-gutter-width;
        height: 100%;
        background-color: #eff1ff;
        position: absolute;
        top: 0;
        right: -$grid-gutter-width/2;
        z-index: 2;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #ffd7d7;
      z-index: 2;
    }
  }
  @include media-breakpoint-down(md) {
    &__row {
      &::before, &:after {
        width: $mobile-container-padding-x;
      }
      &::before {
        left: -$mobile-grid-gutter-width/2;
      }
      &::after {
        right: -$mobile-grid-gutter-width/2;
      }
    }
    &__col {
      position: relative;
      &:not(:last-child) {
        &:after {
          width: $mobile-grid-gutter-width;
          right: -$mobile-grid-gutter-width/2;
        }
      }
    }
  }
}
